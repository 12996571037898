/**=====================
    3.26 Landing CSS Start
==========================**/
::selection {
  background-color: rgba($primary-color, 0.2);
  color: $secondary-color;
}
::-moz-selection {
  background-color: $primary-color;
  color: $secondary-color;
}
$landing-color: $primary-color;
.landing-page {
  .fluid-space {
    padding: 0 calc(30px + (200 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  position: relative;
  .section-py-space {
    padding-top: 70px;
    padding-bottom: 70px;
    .title {
      margin-bottom: 70px;
      @media (max-width: 480px) {
        margin-bottom: 40px;
      }
      h5 {
        color: $secondary-color;
        text-align: center;
        font-family: $landing-cursive;
        font-weight: 700;
        font-size: 20px;
        @media (max-width: 480px) {
          font-size: 16px;
        }
        @media (max-width: 375px) {
          font-size: 12px;
        }
      }
      h2 {
        font-size: 40px;
        color: $theme-body-font-color;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 5px;
          left: 50%;
          transform: translate(-50%);
          width: 51px;
          top: 60px;
          border-radius: 10px;
          background-color: $primary-color;
        }
        @media (max-width: 1199px) {
          font-size: 30px;
          &::after {
            top: 50px;
          }
        }
        @media (max-width: 575px) {
          font-size: 26px;
          &::after {
            top: 40px;
            width: 40px;
          }
        }
        @media (max-width: 480px) {
          font-size: 20px;
          &::after {
            top: 30px;
            width: 30px;
          }
        }
        @media (max-width: 375px) {
          font-size: 14px;
          &::after {
            top: 25px;
            width: 25px;
          }
        }
      }
      span {
        letter-spacing: 1.5px;
        color: rgba($theme-body-font-color, 0.8);
        @media (max-width: 1199px) {
          font-size: 12px;
        }
      }
    }
    .support-title {
      z-index: 8;
      position: absolute;
      right: 50%;
      transform: translate(36%);
      h2 {
        font-weight: 700;
        font-size: $heading_2;
        text-align: end;
      }
      .premium-button {
        text-align: end;
      }
      p {
        letter-spacing: 1.5px;
        text-align: end;
        color: $theme-body-sub-title-color;
        margin-bottom: 0px;
        padding-bottom: 8px;
      }
    }
    .license-title {
      h3 {
        position: absolute;
        top: 38%;
        left: 3%;
        transform: translate(10%);
        color: $primary-color;
        text-align: center;
        font-family: $landing-cursive;
        font-weight: 700;
        font-size: 43px;
      }
    }
  }
  .demo-section {
    .dashboard-images {
      .demo-box {
        .img-wrraper {
          img {
            border-radius: 10px;
            padding: 10px;
            border: 1px solid rgba($primary-color, 0.65);
            &:hover {
              -webkit-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.16);
              -moz-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.16);
              box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.16);
            }
          }
        }
      }
    }
    .down-loader {
      position: relative;
      img {
        position: absolute;
        top: 310px;
      }
    }
    .demo-box {
      background-color: rgba($primary-color, 0.1);
      padding: 0px 21px 20px;
      border-radius: 10px;
      .layout-name {
        padding: 18px 0px 18px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          font-size: 17px;
          font-weight: 600;
          color: $primary-color;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        .riho-demo-img {
          ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            li {
              height: 10px;
              width: 10px;
              border-radius: 100%;
              opacity: 0.5;
              &:first-child {
                background-color: $primary-color;
              }
              &:nth-child(2) {
                background-color: $danger-color;
              }
              &:last-child {
                background-color: $warning-color;
              }
            }
          }
        }
      }
      .demo-title {
        padding-top: 20px;
        text-align: center;
        .btn {
          text-transform: capitalize;
          border: 1px solid $primary-color;
          padding: 8px 15px;
          transition: all 0.5s ease;
          color: $white;
          &:hover {
            background-color: $primary-color;
            color: $white;
          }
        }
      }
      .img-wrraper {
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        img {
          transition: all 0.4s ease;
          width: 100%;
        }
      }
      &:hover {
        .img-wrraper {
          transition: all 0.4s ease;
        }
      }
    }
    .demo-block {
      margin-bottom: -30px;
      justify-content: center;
      > div {
        margin-bottom: 30px;
      }
    }
  }
  .sub-title {
    font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    font-family: $landing-cursive;
    color: var(--theme-default);
    margin-top: -14px;
    line-height: 1.8;
    @media (max-width: 1540px) {
      margin-top: -13px;
    }
    @media (max-width: 1401px) {
      margin-top: -12px;
    }
    @media (max-width: 1266px) {
      margin-top: -11px;
    }
    @media (max-width: 1155px) {
      margin-top: -10px;
    }
    @media (max-width: 991px) {
      margin-top: -11px;
    }
    @media (max-width: 769px) {
      margin-top: -10px;
    }
    @media (max-width: 592px) {
      margin-top: -9px;
    }
  }
  .framework-section {
    .frameworks-list {
      text-align: center;
      display: flex;
      justify-content: center;
      gap: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
      flex-wrap: wrap;
      @media (max-width: 1399px) {
        gap: 20px;
      }
      li {
        display: flex;
        align-items: center;
        min-width: 222px;
        gap: 14px;
        padding: 12px;
        background: rgba(46, 50, 61, 1);
        border-radius: 5px;
        width: fit-content;
        // box-shadow: 0px 1.9217px 1.53736px 0px rgba(0, 157, 181, 0.03), 0px 4.6181px 3.69448px 0px rgba(0, 157, 181, -0.95), 0px 8.69547px 6.95638px 0px rgba(0, 157, 181, 0.06), 0px 10px 10px 0px rgba(0, 157, 181, 0.04);
        position: relative;
        cursor: pointer;
        h5 {
          color: $white;
        }
        @media (max-width: 1399px) {
          min-width: 200px;
        }
        &:hover {
          background: linear-gradient(260deg, #0eabab -0.05%, #009db5 96%);
        }
        div {
          height: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
          width: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          // background-color: rgba($primary-color, 0.1);
          img {
            width: calc(20px + (48 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (48 - 20) * ((100vw - 320px) / (1920 - 320)));
          }
        }
        img {
          transition: all 0.4s ease;
          &.img-68 {
            width: 68px;
          }
        }
        &:hover {
          > div {
            img {
              animation: bounceIn 2s linear infinite;
              transition: all 0.4s ease;
            }
          }
        }
      }
    }
  }
  .framework {
    scroll-behavior: smooth;
    overflow: hidden;
    background-size: cover;
    height: auto;
    background-color: rgba($primary-color, 0.06);
    ul.framworks-list {
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 40px;
      flex-wrap: wrap;
      margin-bottom: 1px;
      li {
        display: inline-block;
        display: flex;
        gap: 20px;
        align-items: center;
        min-width: 222px;
        gap: 10px;
        padding: 12px 30px 12px 12px;
        background-color: $white;
        border-radius: 10px;
        width: fit-content;
        box-shadow: 0px 1.9217px 1.53736px 0px rgba(92, 97, 242, 0.03),
          0px 4.6181px 3.69448px 0px rgba(92, 97, 242, 0.05),
          0px 8.69547px 6.95638px 0px rgba(92, 97, 242, 0.06),
          0px 10px 10px 0px rgba(92, 97, 242, 0.04);
        div {
          height: 58px;
          width: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          background-color: rgba($primary-color, 0.1);
        }
        &:nth-child(13) {
          margin-bottom: 0px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        img {
          transition: all 0.3s ease;
          &.img-68 {
            width: 68px;
          }
          height: 32px;
        }
        position: relative;
        h5 {
          margin-bottom: unset;
        }
        &:hover {
          background-color: $white;
          &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0px;
            border-radius: 10px;
          }
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    .wavy-gif {
      left: 100%;
      top: 23%;
      svg {
        width: 80px;
        transform: rotate(90deg);
        @media (max-width: 1199px) {
          width: 60px;
        }
        @media (max-width: 575px) {
          width: 30px;
        }
      }
      path {
        stroke: $warning-color;
        stroke-dasharray: 500;
        stroke-dashoffset: 500;
        animation: dash 1.5s linear infinite;
      }
      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
      @media (max-width: 1399px) {
        right: 3%;
        top: 15%;
      }
      @media (max-width: 991px) {
        right: 1%;
        top: 10%;
      }
      @media (max-width: 767px) {
        top: 8%;
      }
    }
  }
  .application-section {
    background-color: $white;
    .application {
      .application-block {
        justify-content: center;
        .demo-box {
          background-color: rgba($primary-color, 0.1);
          padding-bottom: unset !important;
          padding: 20px;
          border-radius: 10px;
          .img-wrraper {
            overflow: hidden;
            position: relative;
            img {
              transition: all 0.5s ease;
              width: 100%;
            }
          }
          .demo-detail {
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .btn {
              border-bottom: unset !important;
              border-radius: 15px 15px 0px 0px !important;
            }
          }
          &:hover {
            box-shadow: 0 3px 9px 3px rgba(169, 184, 200, 0.15);
            .img-wrraper {
              img {
                transform: scale(1.05);
                transition: all 0.5s;
              }
            }
          }
        }
      }
    }
  }
  .feature-section {
    scroll-behavior: smooth;
    overflow: hidden;
    background-size: cover;
    height: 610px;
    padding-top: 70px;
    background-color: rgba($primary-color, 0.06);
    .vector-image {
      top: 50px;
      right: -170px;
      @media (max-width: 1674px) {
        top: 60px;
        right: -160px;
      }
      @media (max-width: 1600px) {
        top: 0px;
        right: -20px;
      }
      @media (max-width: 1655px) {
        right: -130px;
      }
      @media (max-width: 1595px) {
        right: -6px;
        top: -10px;
      }
      @media (max-width: 1399px) {
        top: -6px;
        right: -10px;
      }
      @media (max-width: 1199px) {
        right: -12px;
      }
      img {
        height: 370px;
        @media (max-width: 1674px) {
          height: 340px;
        }
        @media (max-width: 1655px) {
          height: 310px;
        }
        @media (max-width: 1600px) {
          height: 300px;
        }
        @media (max-width: 1399px) {
          height: 280px;
        }
      }
    }
    .round-gif {
      top: unset;
      bottom: 12%;
      @media (max-width: 1660px) {
        left: 0;
      }
      @media (max-width: 1460px) {
        bottom: unset;
        top: 6%;
      }
      @media (max-width: 991px) {
        top: -3%;
      }
      @media (max-width: 575px) {
        top: -2%;
      }
      img {
        width: 105px;
        height: 105px;
        @media (max-width: 1460px) {
          width: 90px;
          height: 90px;
        }
        @media (max-width: 575px) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  .feature-box {
    padding: 40px 22px 21px 28px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    @media (max-width: 575px) {
      padding: 20px;
    }
    img {
      transition: all 0.3s ease;
    }
    &:hover {
      background-color: $primary-color;
      h5 {
        color: $white;
        font-weight: 600;
      }
      p {
        color: $white;
      }
      img {
        transform: scale(0.7);
        animation: swing 2s ease infinite;
      }
      .feature-icon {
        background-color: rgba($primary-color, 0.08);
        .bg-1 {
          svg {
            fill: var(--theme-default);
          }
        }
        .bg-2 {
          svg {
            fill: var();
          }
        }
        .bg-3 {
          svg {
            fill: var(--features-svg-3);
          }
        }
        .bg-4 {
          svg {
            fill: var(--features-svg-4);
          }
        }
        .bg-5 {
          svg {
            fill: var(--features-svg-5);
          }
        }
        .bg-6 {
          svg {
            fill: var(--features-svg-6);
          }
        }
        .bg-7 {
          svg {
            fill: var(--features-svg-7);
          }
        }
        .bg-8 {
          svg {
            fill: var(--features-svg-8);
          }
        }
      }
    }
    h5 {
      padding: 11px 0px;
      font-weight: 600;
    }
    .feature-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 17px;
      top: -20px;
      left: 22px;
      box-shadow: 5px 7px 4px 0px rgba(0, 0, 0, 0.2);
      div {
        height: 45px;
        width: 45px;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary-color, 0.08);
      }
    }
  }
  .feature-section {
    scroll-behavior: smooth;
    overflow: hidden;
    background-size: cover;
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: rgba($primary-color, 0.06);
    .vector-image {
      top: 50px;
      right: -170px;
      @media (max-width: 1674px) {
        top: 60px;
        right: -160px;
      }
      @media (max-width: 1600px) {
        top: 0px;
        right: -20px;
      }
      @media (max-width: 1655px) {
        right: -130px;
      }
      @media (max-width: 1595px) {
        right: -6px;
        top: -10px;
      }
      @media (max-width: 1399px) {
        top: -6px;
        right: -10px;
      }
      @media (max-width: 1199px) {
        right: -12px;
      }
      img {
        height: 370px;
        @media (max-width: 1674px) {
          height: 340px;
        }
        @media (max-width: 1655px) {
          height: 310px;
        }
        @media (max-width: 1600px) {
          height: 300px;
        }
        @media (max-width: 1399px) {
          height: 280px;
        }
      }
    }
    .round-gif {
      top: unset;
      bottom: 12%;
      @media (max-width: 1660px) {
        left: 0;
      }
      @media (max-width: 1460px) {
        bottom: unset;
        top: 6%;
      }
      @media (max-width: 991px) {
        top: -3%;
      }
      @media (max-width: 575px) {
        top: -2%;
      }
      img {
        width: 105px;
        height: 105px;
        @media (max-width: 1460px) {
          width: 90px;
          height: 90px;
        }
        @media (max-width: 575px) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  .common-card {
    background: #fcfcfd;
    border: 2px solid $white;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    border-radius: 10px;
  }
  .section-pt-space {
    padding-top: 70px;
  }
  .section-pb-space {
    padding-bottom: 70px;
  }
  .title {
    margin: 0px 0px 35px;
  }
  .footer-bg {
    position: relative;
    background: url(../../images/landing/footer.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
    @media (max-width: 575px) {
      background-position: center;
    }
  }
  .sub-footer {
    margin-top: 0;
    padding-top: 60px;
    padding-bottom: 54px;
    align-items: center;
    @media (max-width: 874px) and (min-width: 320px) {
      padding-block: 35px;
    }
  }
  .landing-center {
    padding-top: 54px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    @media (max-width: 767px) and (min-width: 320px) {
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .feature-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 874px) {
        display: block;
        text-align: center;
      }
      > div {
        h2 {
          color: var(--white);
          max-width: 65%;
          padding-bottom: 8px;
          @media (max-width: 874px) {
            max-width: 100%;
          }
        }
        .footer-rating {
          text-align: left;
          @media (max-width: 890px) and (min-width: 320px) {
            padding-top: 6px;
          }
          @media (max-width: 874px) {
            text-align: center;
            padding-top: 0;
            padding-bottom: 12px;
          }
          svg {
            width: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
            height: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
            &.stroke-warning {
              stroke: $warning-color !important;
              fill: $dark-body-background !important;
            }
            &.fill-warning {
              stroke: $warning-color !important;
            }
          }
        }
      }
    }
  }
  .left-subfooter {
    text-align: left;
    img {
      height: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    }
    p {
      color: var(--white);
      padding-top: 12px;
    }
  }
  .fill-warning {
    fill: $warning-color;
  }
  .premium-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 41px;
    img {
      height: calc(120px + (350 - 160) * ((100vw - 320px) / (1920 - 320)));
      animation: up-down 4s infinite;
    }
  }
  .premium-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    text-align: left;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
    &::before {
      content: "";
      width: 3px;
      height: 100%;
      background: linear-gradient(
        295deg,
        rgba(255, 255, 255, 0) 0%,
        #5c61f2 42.7%,
        rgba(0, 102, 102, 0) 84.52%
      );
      position: absolute;
      top: 0;
      left: 0;
      @media (max-width: 767px) {
        display: none;
      }
    }
    h3 {
      font-size: calc(20px + (32 - 20) * ((100vw - 300px) / (1920 - 300)));
    }
    h2 {
      font-size: calc(18px + (35 - 18) * ((100vw - 300px) / (1920 - 300)));
      padding-top: 6px;
      padding-bottom: 10px;
      text-transform: capitalize;
    }
    span {
      margin-bottom: 55px;
      color: #717178;
      @media (max-width: 1550px) and (min-width: 1241px) {
        margin-bottom: 30px;
      }
      @media (max-width: 1240px) and (min-width: 320px) {
        margin-bottom: 16px;
      }
      @media (max-width: 991px) and (min-width: 320px) {
        text-overflow: ellipsis;
        height: 47px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
  }
  .premium-section {
    .container {
      @media (max-width: 575px) {
        width: calc(285px + (600 - 285) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
  .right-subfooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 12px;
    @media (max-width: 768px) and (min-width: 320px) {
      gap: 6px;
    }
    @media (max-width: 767px) and (min-width: 320px) {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: 730px) and (min-width: 320px) {
      display: block;
    }
    h3 {
      color: var(--white);
    }
    ul {
      display: flex;
      gap: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
      align-items: center;
      @media (max-width: 730px) and (min-width: 320px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        padding-top: 10px;
      }
      li {
        a {
          color: $warning-color;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }
  }
  // landing header
  .custom-container {
    max-width: 1600px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
  .navbar-nav {
    align-items: center;
    .nav-item {
      position: relative;
      .nav-link {
        color: $black;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 12px;
        transition: all 0.3s ease;
        opacity: 0.7;
        &:hover,
        &.active {
          opacity: 1;
          text-shadow: 0 0 0 $primary-color;
          transition: all 0.3s ease;
          color: $primary-color;
        }
      }
    }
  }
  .btn-landing {
    border-radius: 5px;
    color: $white;
    background-color: $primary-color;
    padding: 14px 23px;
    line-height: 1;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: fit-content;
    &:hover {
      color: $white;
    }
  }
  .landing-header {
    background-color: $dark-card-background;
    padding: 18px 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    ul.landing-menu {
      li.nav-item {
        &.menu-back {
          border-bottom: 1px solid $light-gray;
          padding: 30px 20px;
          text-align: right;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 16px;
          display: none;
          cursor: pointer;
          margin-bottom: 15px;
          i {
            font-size: 16px;
            margin-left: 10px;
          }
        }
        a.nav-link {
          font-size: 16px;
          font-weight: 500;
          color: $white;
          padding: 10px 20px;
          position: relative;
        }
        &:hover {
          a.nav-link {
            color: $secondary-color;
          }
        }
        &:active {
          a.nav-link {
            color: $secondary-color;
          }
        }
      }
    }
    .buy-block {
      display: flex;
      align-items: center;
      .toggle-menu {
        margin-left: 20px;
        display: none;
        cursor: pointer;
        i {
          font-size: 24px;
          color: $white;
        }
      }
    }
  }
  // landing home
  // .landing-home {
  //   scroll-behavior: smooth;
  //   overflow: hidden;
  //   position: relative;
  //   background-color: $white;
  //   .home-bg {
  //     padding: 70px 0 270px;
  //     background-image: url(../images/landing/landing-home/home-bg.jpg);
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     background-size: cover;
  //     display: flex;
  //     position: relative;
  //     overflow: hidden;
  //     // @media (max-width: 578px) {
  //     //   margin: 78px 15px 64px;
  //     // }
  //     .bg-icon-images {
  //       li {
  //         position: absolute;
  //         &:first-child {
  //           top: 11%;
  //           left: 44%;
  //           animation: rounded 1.5s infinite linear;
  //         }
  //         &:nth-child(2) {
  //           bottom: 32%;
  //           left: 36%;
  //           animation: bounce-effect 3s infinite ease-in;
  //         }
  //         &:nth-child(3) {
  //           top: -70px;
  //           right: -57px;
  //           animation: bounce-effect 2s infinite ease-in;
  //         }
  //         &:nth-child(4) {
  //           top: 11%;
  //           left: 0px;
  //           animation: bounce-effect 2s infinite ease-in;
  //         }
  //       }
  //     }
  //     .home-text {
  //       position: relative;
  //       padding: 55px 94px 0 104px;
  //       @media (max-width: 1830px) {
  //         padding: 55px 75px 0 90px;
  //       }
  //       @media (max-width: 1400px) {
  //         padding: 55px 50px 0 63px;
  //       }
  //       @media (max-width: 1200px) {
  //         padding: 0px 28px 0 30px;
  //       }
  //       @media (max-width: 578px) {
  //         padding: 0px 15px 0 15px;
  //       }
  //       .main-title {
  //         border-radius: 30px;
  //         padding: 0px 15px 0px 0px;
  //         width: fit-content;
  //         margin-bottom: 15px;
  //         .project-name{
  //           color: $secondary-color;
  //           font-family: $landing-cursive;
  //           font-size: 26px;
  //           font-weight: 400;
  //         }
  //         .d-flex {
  //           .flex-grow-1 {
  //             p {
  //               font-size: 18px;
  //               color: $secondary-color;
  //               font-weight: 500;
  //               @media (max-width: 388px) {
  //                 font-size: 13px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //       h2 {
  //         color: $white;
  //         font-size: calc(12px + (68 - 23) * ((100vw - 320px) / (1920 - 320)));
  //         font-weight: 600;
  //         margin-bottom: 10px;
  //         position: relative;
  //         z-index: 2;
  //         .line-text {
  //           position: absolute;
  //           top: 58%;
  //           left: 2%;
  //           z-index: -1;
  //           animation: arrowsvg 3s infinite;
  //           @media (max-width: 1830px) {
  //             left: 44%;
  //           }
  //           @media (max-width: 1532px) {
  //             width: 174px;
  //             top: 58%;
  //           }
  //           @media (max-width: 1400px) {
  //             width: 129px;
  //           }
  //           @media (max-width: 1199px) {
  //             left: 36%;
  //             width: 122px;
  //           }
  //           @media (max-width: 991px) {
  //             left: 0%;
  //             width: 98px;
  //             top: 88%;
  //           }
  //           @media (max-width: 892px) {
  //             width: 68px;
  //           }
  //           @media (max-width: 892px) {
  //             width: 104px;
  //           }
  //           @media (max-width: 479px) {
  //             display: none;
  //           }
  //         }
  //         @keyframes arrowsvg {
  //           0% {
  //             transform: scale(0);
  //             transform-origin: left;
  //           }
  //           100% {
  //             transform: scale(1);
  //             transform-origin: left;
  //           }
  //         }
  //         @keyframes rounded {
  //           0% {
  //             transform: rotate(0deg);
  //           }
  //           100% {
  //             transform: rotate(360deg);
  //           }
  //         }
  //         @keyframes bounce-effect {
  //           0% {
  //             transform: translateY(0);
  //             transition: 0.5s;
  //           }
  //           50% {
  //             transform: translateY(-5px);
  //             transition: 0.5s;
  //           }
  //           100% {
  //             transform: translateY(0);
  //             transition: 0.5s;
  //           }
  //         }
  //       }
  //       p {
  //         font-size: 17px;
  //         font-weight: 400;
  //         color: $white;
  //         margin-bottom: 34px;
  //         &.description-name{
  //           font-size: 20px;
  //           font-weight: 500;
  //           font-family: $landing-dmsans;
  //           text-overflow:ellipsis;
  //           text-overflow:ellipsis;
  //           overflow:hidden;
  //           display: -webkit-box !important;
  //           -webkit-line-clamp: 3;
  //           -webkit-box-orient: vertical;
  //           white-space: normal;
  //         }
  //       }
  //       .docutment-button {
  //         a {
  //           svg {
  //             fill: $white !important;
  //             margin-right: 6px;
  //             vertical-align: -4px;
  //             height: 20px;
  //             width: 20px;
  //           }
  //           &:first-child {
  //             padding: 10px 12px;
  //             font-size: 16px;
  //             font-weight: 500;
  //             margin-right: 10px;
  //             transition: all .4s;
  //             &:hover {
  //               color: $white !important;
  //             }
  //           }
  //           &:last-child {
  //             padding: 10px 13px;
  //           }
  //         }
  //       }
  //     }
  //     .home-screen {
  //       position: absolute;
  //       padding-right: 104px;
  //       top: -241px;
  //       @media (max-width: 991px) {
  //         padding-right: 30px;
  //       }
  //       @media (max-width: 767px) {
  //         display: none;
  //       }
  //       .dashboard-img{
  //         position: relative;
  //       }
  //       .charts-card{
  //         .dashboard-card {
  //           li{
  //             &:first-child{
  //               bottom: 21%;
  //               left: -82px;
  //               z-index: 1;
  //               position: absolute;
  //               animation: bounce-effect 2s infinite ease-in;
  //             }
  //             &:nth-child(2){
  //               top: 26%;
  //               left: 232px;
  //               z-index: 1;
  //               width: 8%;
  //               transform: translate(0px, -4px);
  //               position: absolute;
  //             }
  //             &:last-child{
  //               top: 12%;
  //               right: 71px;
  //               z-index: 1;
  //               position: absolute;
  //               animation: bounce-effect 2s infinite ease-in;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .wave-vibe{
  //     position: absolute;
  //     bottom: -2px;
  //     .wave-img{
  //       z-index: 1;
  //     }
  //   }
  //   .sub-title {
  //     font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
  //   }
  //   .content {
  //     h1 {
  //       color: $white;
  //       line-height: 1.2;
  //       font-size: calc(25px + (60 - 25) * ((100vw - 300px) / (1920 - 300)));
  //       text-transform: uppercase;
  //       span {
  //         background: linear-gradient(90deg, #FFB905 20.35%, #6AEBAD 50%),
  //           linear-gradient(0deg, #FFFFFF, #FFFFFF);
  //         -webkit-background-clip: text;
  //         -webkit-text-fill-color: transparent;
  //       }
  //     }
  //     p {
  //       font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
  //       font-weight: 500;
  //       color: $white;
  //       opacity: 0.8;
  //       width: 64%;
  //       margin: 0 auto;
  //       @media (max-width: 1366px) {
  //         width: 90%;
  //       }
  //       @media (max-width: 767px) {
  //         width: 100%;
  //       }
  //     }
  //   }
  //   .content-title {
  //     position: relative;
  //     display: inline-block;
  //     .arrow-decore {
  //       position: absolute;
  //       left: -203px;
  //       top: -25px;
  //       @media (max-width: 1660px) {
  //         left: -166px;
  //         width: 150px;
  //       }
  //       @media (max-width: 991px) {
  //         left: -125px;
  //         width: 110px;
  //         top: -14px;
  //       }
  //       @media (max-width: 575px) {
  //         left: -61px;
  //         width: 60px;
  //         top: -4px;
  //       }
  //     }
  //   }
  //   .sticky-header {
  //     header {
  //       .nav-padding {
  //         padding: 16px 50px;
  //       }
  //     }
  //   }
  // }
  .components-section {
    .flower-gif {
      left: unset;
      right: 5%;
      top: 20%;
      @media (max-width: 991px) {
        right: 10%;
        top: 9%;
      }
    }
  }
  .component_responsive {
    flex-wrap: initial;
  }
  .component-hover-effect {
    display: flex;
    align-items: center;
    gap: 12px;
    background: rgba($primary-color, 0.04);
    border: 2px solid $white;
    box-shadow: 0px 4px 10px rgba(46, 35, 94, 0.07);
    border-radius: 5px;
    padding: 20px;
    justify-content: center;
    min-height: 78px;
    @media (max-width: 767px) {
      padding: 13px;
      min-height: 68px;
    }
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .components {
    .d-flex {
      border: 1px solid $primary-color;
      padding: 18px 20px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 15px 0 0 0px;
      transition: all 0.4s;
      span {
        position: relative;
        padding: 10px;
        background: rgba($primary-color, 0.1);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          position: absolute;
          left: -5px;
          top: -5px;
          height: 118%;
          width: 118%;
          border-radius: 50px;
          border-width: 1px;
          border-style: dashed;
          border-color: $primary-color;
        }
      }
      svg {
        width: 35px;
        height: 35px;
        fill: $primary-color;
      }
      &:hover {
        background-color: $primary-color;
        transform: translateY(-5px);
        transition: all 0.4s;
        h3 {
          color: $white;
        }
        span {
          background: $white;
          &::before {
            border-color: $white;
            animation: rotate 8s infinite linear;
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes move1 {
    0% {
      transform: rotate(0deg) translateX(15px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(15px) rotate(-360deg);
    }
  }
  @keyframes move2 {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  // demo section
  .demo-section {
    .demo-box {
      background-color: rgba($primary-color, 0.1);
      border-radius: 5px;
      .img-wrraper {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        img {
          transition: all 0.4s ease;
          width: 100%;
        }
      }
      &:hover {
        .img-wrraper {
          img {
            transform: scale(1.02);
          }
        }
      }
      .d-flex {
        align-items: center;
        margin-bottom: 20px;
        .dashboard-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 18px;
            height: 18px;
            fill: $primary-color;
          }
        }
        .flex-grow-1 {
          h6 {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .flex-shrink-0 {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            color: $primary-color;
            font-weight: 500;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            transition: all 0.4s;
          }
        }
      }
      h5 {
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        color: $theme-font-color;
        transition: all 0.4s;
      }
      &:hover {
        h5 {
          color: $primary-color;
          transition: all 0.4s;
        }
        .flex-shrink-0 {
          svg {
            margin-left: 5px;
            transition: all 0.4s;
          }
        }
      }
    }
    .demo-block {
      margin-bottom: -26px;
      padding: 0 28px;
      justify-content: center;
      > div {
        margin-bottom: 26px;
      }
    }
  }
  #Applications {
    .demo-block {
      figure {
        margin-bottom: 26px;
      }
    }
  }
  $color-1: #6362e7;
  $color-2: #10539c;
  $color-3: #2c5f2d;
  $color-4: #0e7c7b;
  $color-5: #5f4b8b;
  $color-6: #c38c81;
  @each $color-name, $color-type in (primary, $color-1), (secondary, $color-2),
    (success, $color-3), (danger, $color-4), (info, $color-5), (light, $color-6)
  {
    .color-#{$color-name} {
      background-color: $color-type;
    }
  }
  .color-theme {
    background: url("../../images/landing/landing-home/home-bg.jpg");
    .owl-theme {
      h3 {
        padding: 18px 0;
        text-align: center;
        margin: 0;
      }
    }
  }
  // counter sec
  .counter-sec {
    background-color: $white;
    .counter-box {
      text-align: center;
      border: 1px solid $light-gray;
      padding: 25px 30px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
      .count-number {
        background-color: rgba($primary-color, 0.1);
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        transition: all 0.5s ease;
        h3 {
          font-size: 40px;
          margin-bottom: unset;
          color: $primary-color;
          transition: all 0.5s ease;
        }
      }
      .count-detail {
        h4 {
          text-transform: capitalize;
        }
        p {
          font-size: 16px;
          color: $light-text;
          margin-bottom: unset;
        }
      }
      &:hover {
        .count-number {
          background-color: $primary-color;
          h3 {
            color: $white;
          }
        }
      }
    }
  }
  // FAQ Section
  .faq-section {
    .title {
      h2 {
        margin-bottom: 12px;
        span {
          color: $secondary-color;
        }
      }
      p {
        text-align: center;
        color: #6c757d;
        font-size: 18px;
      }
    }
    .faq-block {
      margin-bottom: -25px;
      justify-content: center;
      > div {
        margin-bottom: 25px;
      }
    }
    .faq-box {
      border: 1px solid $light-gray;
      padding: 45px 30px;
      border-radius: 10px;
      background-color: $white;
      height: 100%;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;
      h4 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
      }
      p {
        font-size: 16px;
        color: $light-text;
        margin-bottom: unset;
      }
      &:hover {
        transform: translateY(-5px);
        background-color: $primary-color;
        transition: all 0.4s;
        h4 {
          color: $white;
        }
        p {
          color: $dark-all-font-color;
          a {
            color: $secondary-color;
          }
        }
      }
    }
  }
  // footer //
  .landing-footer {
    .footer-contain {
      text-align: center;
      img {
        margin-bottom: 20px;
      }
      h2 {
        font-size: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 1.4;
        color: $white;
        font-weight: 500;
        margin-bottom: 15px;
        line-height: 1.2;
      }
      .star-rate {
        margin-bottom: 40px;
        li {
          display: inline-block;
          i {
            font-size: calc(
              16px + (30 - 16) * ((100vw - 320px) / (1920 - 320))
            );
          }
        }
      }
      .btn-footer {
        a {
          &:nth-child(n + 2) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.search-popup-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-close-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: rgb(252, 105, 105);
}

.responsive-search-icon {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 110px;
  right: 30px;
  [dir="rtl"] & {
    left: 30px;
    right: unset;
  }
  z-index: 9;
  color: #fff;
  background: var(--theme-default);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
  opacity: 0.9;
  transition: all 0.3s ease;
  &:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }
  svg {
    width: 20px;
  }
}
@media (max-width: 768px) {
  .responsive-search-icon {
    display: block;
  }
}

.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 65px;
  right: 30px;
  [dir="rtl"] & {
    left: 30px;
    right: unset;
  }
  z-index: 9;
  color: #fff;
  background: var(--theme-default);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
  opacity: 0.5;
  transition: all 0.3s ease;
  &:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }
  svg {
    width: 20px;
  }
}
.gradient-10 {
  background: linear-gradient(140deg, #4a86fb 45%, #ccc89a 68.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.classic-effect {
  position: relative;
  overflow: hidden;
  &:before,
  &:after {
    background: var(--theme-default);
    width: 25%;
    position: absolute;
    content: "";
    opacity: 0;
    -webkit-transition: all 0.3s steps(4);
    transition: all 0.3s steps(4);
    z-index: 1;
    bottom: 100%;
    top: 0;
  }
  &:before {
    left: 0;
    transition-delay: 0s;
  }
  &:after {
    left: 25%;
    transition-delay: 0.025s;
  }
  span {
    &:before,
    &:after {
      background: var(--theme-default);
      width: 25%;
      position: absolute;
      content: "";
      opacity: 0;
      -webkit-transition: all 0.3s steps(4);
      transition: all 0.3s steps(4);
      z-index: 1;
      bottom: 100%;
      top: 0;
    }
    &:before {
      left: 50%;
      transition-delay: 0.05s;
    }
    &:after {
      left: 75%;
      transition-delay: 0.15s;
    }
  }
  &:hover {
    &:before,
    &:after {
      bottom: 0;
      opacity: 0.5;
    }
    span {
      &:before,
      &:after {
        bottom: 0;
        opacity: 0.5;
      }
    }
  }
}
.action {
  .shap-block {
    .rounded-shap {
      i {
        right: -1%;
        top: -55%;
      }
    }
  }
}
/**=====================
    3.26 Landing CSS Ends
==========================**/
