.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 38px;
  width: auto;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &.is-invalid {
    border-color: #dc3545 !important;
  }
  &.is-valid {
    border-color: #198754 !important;
  }
  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
    border-color: #86b7fe;
    background-color: #00000000;
    border-radius: 0.375rem;
  }
  &.is-valid:focus-within {
    border-color: #198754 !important;
    box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%) !important;
    border-radius: 0.375rem;
    outline: 0;
  }
  &.is-invalid:focus-within {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
    border-radius: 0.375rem;
    outline: 0;
  }
  input {
    flex: 1;
    border: none;
    height: 38px;
    font-size: 14px;
    padding: 4px 0 0 0;
    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
  .tag-title {
    margin-top: 0px;
  }
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}
