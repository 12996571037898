/**=====================
    3.22 Job-search CSS Start
==========================**/
.filter-cards-view {
  .job-filter {
    &:first-child {
      margin-bottom: 30px;
    }
  }
  .checkbox-animated {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.location-checkbox {
  span {
    color: $light-text;
    padding-left: 33px;
    [dir="rtl"] & {
      padding-left: unset;
      padding-right: 33px;
    }
  }
}
.job-accordion {
  .card {
    margin-bottom: 30px;
  }
  .btn-block {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.job-search {
  p {
    margin-top: 15px;
  }
  .d-flex {
    .flex-grow-1 {
      h6 {
        margin-bottom: 3px;
        span {
          font-size: 13px;
          color: $light-text;
          font-weight: normal;
          &.badge {
            color: $white;
          }
        }
      }
      p {
        margin-top: 0;
        color: $light-text;
      }
    }
  }
  .job-description {
    margin-top: 30px;
    h3 {
      margin-bottom: 10px;
    }
    h6 {
      font-weight: 600;
    }
    p {
      margin-top: 0;
      margin-bottom: 6px;
    }
    ul {
      list-style-type: disc;
      list-style-position: inside;
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .theme-form {
      margin-top: 30px;
      margin-bottom: 30px;
      .row {
        div[class^="col-"] {
          +div[class^="col-"] {
            .select2-container {
              margin-top: 18px;
            }
          }
          .job-select2 {
            ~.select2-container {
              margin-top: 0 !important;
            }
          }
          .input-group {
            .datepicker-here {
              font-size: 14px;
              border: 1px solid $light-semi-gray;
              padding-top: 12px;
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
}
.select2-container {
  .select2-selection--single {
    border-radius: 0.25rem !important;
    border-color: $light-semi-gray;
    height: 47px !important;
    padding: 10px;
  }
}
.job-pagination {
  margin-bottom: 30px;
  nav {
    ul {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;
    .job-filter {
      &:first-child {
        margin-bottom: 20px;
      }
    }
    .checkbox-animated {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .job-accordion {
    .animate-chk {
      padding: 20px !important;
    }
  }
  .job-search {
    p {
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;
    .job-filter {
      &:first-child {
        margin-bottom: 15px;
      }
    }
    .checkbox-animated {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .job-accordion {
    .animate-chk {
      padding: 15px !important;
    }
  }
  .job-search {
    p {
      margin-top: 15px;
    }
    .d-flex {
      display: block;
      text-align: center;
      .flex-grow-1 {
        h6 {
          .pull-right {
            float: none;
            margin-top: 3px;
            display: block;
          }
          a {
            display: block;
          }
        }
        p {
          span {
            +span {
              display: block;
            }
          }
        }
      }
      img {
        margin-bottom: 15px;
      }
      .m-r-20 {
        margin-right: 0;
      }
    }
    .job-description {
      margin-top: 15px;
      .theme-form {
        margin-top: 15px;
        margin-bottom: 15px;
        .row {
          div[class^="col-"] {
            +div[class^="col-"] {
              .select2-container {
                margin-top: 2px;
              }
            }
          }
        }
        .xs-mt-period {
          margin-top: 15px;
        }
      }
    }
  }
  .job-pagination {
    margin-bottom: 15px;
  }
  .xl-mt-job {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .job-search {
    .job-description {
      .btn {
        padding: 6px 12px;
      }
    }
  }
}
// job-apply
.select-date {
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        top: 9px;
        right: 12px;
      }
    }
  }
}
/**=====================
    3.22 Job-search CSS End
==========================**/