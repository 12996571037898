/* Menu.css */

/* Default horizontal scrolling menu styles */

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-grow-1 {
    flex-grow: 1;
}

.w-100 {
    width: 100%;
}

.o-hidden {
    overflow: hidden;
}
.position_unset {
    position: unset;
}
.dropdown_btn {
    button {
        background-color: transparent !important;
        border: none !important;
        color: $theme-font-color;
    }
}
.cust-dropdown-menu {
    position: absolute;
    top: 45px;
    padding: 10px;
    display: block;
    border-radius: 10px;
    border: 1px solid gray;
}
.cust-dropdown-menu {
    li {
        line-height: normal;
        padding: 5px 0px;
        a {
            color: $theme-font-color;
            @media (max-width: 960px) {
                font-size: 9px;
            }
            h6 {
                @media (max-width: 960px) {
                    font-size: 9px;
                }
                @media (max-width: 768px) {
                    font-size: 12px;
                }
            }
            p {
                @media (max-width: 960px) {
                    font-size: 7px;
                }
                @media (max-width: 768px) {
                    font-size: 11px;
                }
            }
        }
    }
}
.dropdown-open {
    overflow: hidden;
}

.dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
}
.nav {
    display: flex;
    /* overflow-x: auto; */
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.nav::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.nav-item {
    display: inline-block; /* Ensure nav items stay inline */
    .nav-link {
        display: inline-block;
        padding: 5px 10px;
        color: $theme-font-color;
        font-weight: 500;
        font-size: 14px;
        /* margin-left: 10px; */
        @media (max-width: 1280px) {
            font-size: 11px;
            padding: 5px 2px;
        }
        @media (max-width: 960px) {
            font-size: 9px;
            padding: 5px 2px;
        }
        @media (max-width: 960px) {
            font-size: 9px;
            padding: 5px 8px;
        }
        svg {
            @media (max-width: 960px) {
                height: 8px;
            }
            @media (max-width: 768px) {
                height: 15px;
            }
        }
    }
}

.btn-left,
.btn-right {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.text-dark {
    color: #000;
}

.text-light {
    color: #fff;
}
.menu_wraper {
    position: fixed;
    top: 80px;
    z-index: 4;
}
.menu_wraper {
    .l-h-45 {
        line-height: 40px;
        @media (max-width: 767.98px) {
            line-height: 20px;
        }
    }
}
.bg_light {
    background-color: #f6f8fc;
}
.btn-sidebar-toggle {
    display: none;
}
.tools_part {
    max-width: 400px;
    h5 {
        font-weight: 700;
        @media (max-width: 960px) {
            font-size: 11px;
        }
    }
}
.tools_col {
    display: flex;
    right: 0;
    text-wrap: wrap;
    overflow-y: auto;
}
.tools_description {
    font-size: 12px;
    margin-bottom: 0;
    @media (max-width: 960px) {
        margin-bottom: 11px;
    }
}
.heading-drop{
@media (max-width: 767.98px) {
    font-size: 14px;
}
}
/* Sidebar Styles */
@media (max-width: 1440px) {
}
@media (max-width: 992px) {
    .menu_wraper {
        position: fixed;
        top: 0px;
        z-index: 999;
    }
    .nav {
        display: block;
        overflow: hidden;
        height: 100vh; /* Full height for the sidebar */
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100% - 40%);
        transform: translateX(-100%); /* Hide the sidebar off-screen */
        transition: transform 0.3s ease;
        background: #fff;
        z-index: 999;
        overflow: auto;
        @media (max-width: 767.98px) {
            width: calc(100% - 35%);
        }
    }
    .overflow_auto {
        overflow: auto;
    }
    .nav-item {
        display: block;
        margin: 0; /* Remove margins */
    }

    .nav-link {
        display: block;
        padding: 15px; /* Add padding to the links */
    }

    .btn-left,
    .btn-right {
        display: none; /* Hide arrows */
    }

    .btn-sidebar-toggle {
        display: block;
        position: fixed;
        top: 15px;
        left: 15px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        z-index: 999;
    }

    .nav.open {
        transform: translateX(0); /* Show the sidebar */
    }
    .tools_part {
        h5 {
            font-size: 14px;
            font-weight: 400;
        }
    }
    .cust-dropdown-menu {
        position: relative;
        border: none;
        padding: 10px 0px 10px 15px;
        max-width: 500px;
        top: 0;
        text-align: left;
        li {
            a {
                font-size: 14px;
            }
        }
    }
    .nav-item .nav-link {
        margin-left: 0;
        font-size: 14px;
    }
}
@media (max-width: 575.98px) {
    .nav_block {
        display: block !important;
    }
}
/* .nav {
    display: block;
} */

.cust-dropdown-menu {
    display: none;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 500px;
    overflow-y: auto;
}

.cust-dropdown-menu.open {
    display: block;
}

.nav-item .nav-link {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.nav-item .fa-chevron-down {
    transition: transform 0.3s ease;
}

.nav-item.open .fa-chevron-down {
    transform: rotate(180deg);
}
.nav-item.active > .nav-link {
    border-radius: 10px;
}
.nav-item.active > .nav-link,
.nav-item.active > ul > .active {
    position: relative;
    color: $white !important; /* Ensure text color is unchanged */
}

.nav-item.active > .nav-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--theme-default);
    z-index: -1; /* Ensure the overlay is behind the text */
}
.nav-item.active > ul > .active > a {
    color: var(--theme-default) !important;
}
.cust-dropdown-menu .active > a {
    color: var(--theme-default) !important;
}
.submenu{
    @media (max-width: 767.98px) {
        /* display: none; */
    }
}
.submenu > li > .active,
.submenu > li > .active h6 {
    color: var(--theme-default) !important;
}
/* .nav-item.open .cust-dropdown-menu {
  display: block;
} */
 .tools_part{
    .fa-chevron-down{
        transform: rotate(0) !important;
        transition: transform 0.3s ease;
    }
    
 }
 .sub_dropdown{
    .fa-chevron-down{
        transform: rotate(180deg) !important;
    }
 }
.sub_dropdown{
    .submenu{
        display: block;
    }
}
.custom-toast-container{
    bottom: 40px !important; 
}