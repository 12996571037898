.popup_radio{ 
    label {
        display: inline-block;
        position: relative;
        padding-left: 5px;
        cursor: pointer;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -20px;
            border: 1px solid $light-semi-gray;
            border-radius: 50%;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 9px;
            height: 9px;
            left: 5px;
            top: 5px;
            margin-left: -20px;
            border-radius: 50%;
            background-color: $primary-color;
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }

    input[type="checkbox"] {
        opacity: 0;

        &:focus {
            &+label {
                &::before {
                    outline-offset: -2px;
                }

            }
        }

        &:checked {
            &+label {
                &::after {
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                }

            }
        }

        &:disabled {
            &+label {
                opacity: 0.65;

                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }

    .radio-inline {
        margin-top: 0;
    } 
}

.popup_rediobtn{ 
    label{ 
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -20px;
            border: 1px solid $light-semi-gray;
            border-radius: 50%;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 9px;
            height: 9px;
            left: 5px;
            top: 5px;
            margin-left: -20px;
            border-radius: 50%;
            background-color: var(--theme-default);
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
        input[type="checkbox"] {
            opacity: 0;
    display: none;
            &:focus {
                &+label {
                    &::before {
                        outline-offset: -2px;
                    }
    
                }
            }
    
            &:checked {
                &label {
                    &::after {
                        -webkit-transform: scale(1, 1);
                        -ms-transform: scale(1, 1);
                        -o-transform: scale(1, 1);
                        transform: scale(1, 1);
                    }
    
                }
            }
    
            &:disabled {
                &+label {
                    opacity: 0.65;
    
                    &::before {
                        cursor: not-allowed;
                    }
                }
            }
        }
       
    }
} 
.transform-scale{
    label{  
                &::after {
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                } 
            
       
    }
 }