/**=====================
    2.21 Loader CSS Start
==========================**/
.loader-wrapper{  
  position: fixed;
  z-index: 999999;
  background: $white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  :root{ 
    --boxSize: 8px;
    --gutter: 8px;
  }
  .loader{
      width: calc((var(--boxSize) + var(--gutter)) * 5);
      height: 64px;
      margin: 50px auto;
      position: relative;
      top: calc(50vh - 75px);
      left: calc(50vw - 30px);
  }
  .loader .box{
      background: $primary-color;
      width: 6px;
      height: 100%;
      margin: 0 2px;
      border-radius: 8px;
      box-shadow: 0px 0px 2px 0px $primary-color;
      display: inline-block;
      transform: scaleY(.4);
      animation: quiet 1.2s ease-in-out infinite;
  }
  .loader .box:nth-child(2){ animation: animate 1.2s ease-in-out infinite; }
  .loader .box:nth-child(4){ animation: loud 1.2s ease-in-out infinite; }
  @keyframes quiet{
      25%{ transform: scaleY(.6); }
      50%{ transform: scaleY(.4); }
      75%{ transform: scaleY(.8); }
  }
  @keyframes animate{
      25%{ transform: scaleY(1); }
      50%{ transform: scaleY(.4); }
      75%{ transform: scaleY(.6); }
  }
  @keyframes loud{
      25%{ transform: scaleY(1); }
      50%{ transform: scaleY(.4); }
      75%{ transform: scaleY(1.2); }
  }
}

/**=====================
    2.21 Loader CSS Ends
==========================**/