/**=====================
    2.13 Datapicker CSS Start
==========================**/
//colors
.clockpicker-canvas {
  line {
    stroke: var(--theme-default);
  }
}
.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: var(--theme-default);
}
.datepicker {
  z-index: 99;
}
.datepicker--day-name {
  color: $primary-color;
  font-weight: bold;
}
.datepicker--cell {
  &.-current- {
    color: $black;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid var(--theme-default);
  }
  &.-focus- {
    background: var(--theme-default);
    color: $white;
  }
  &.-selected- {
    background: var(--theme-default) !important;
  }
  &-day {
    width: 14.2857142857%;
  }
}
.datepicker--cell-day {
  height: 45px;
}
.datepickers-container,
.bootstrap-datetimepicker-widget {
  z-index: 7;
}
.datetime-picker {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
  }
  .input-group-text i {
    line-height: 1.3;
  }
}
// timepicker
.clockpicker-popover {
  border: none;
  box-shadow: $card-box-shadow;
  z-index: 7;
  .popover-content {
    background-color: rgba($primary-color, 0.05);
  }
}
.clockpicker-canvas-bg {
  fill: lighten($primary-color, 38%);
}
.clockpicker-tick {
  &:hover {
    color: inherit;
    background-color: lighten($primary-color, 38%);
  }
}
// calendar
.calendar-basic {
  .md-sidebar-aside {
    background-color: $white;
  }
  #external-events {
    padding: 20px;
    border: 1px solid $light-background;
    h4 {
      margin-bottom: 14px;
    }
    p {
      margin-top: 12px;
    }
    .fc-h-event {
      padding: 12px;
      background-color: var(--theme-default);
      border: 1px solid var(--theme-default);
      +.fc-h-event {
        margin-top: 10px;
      }
      .fc-event-main {
        font-size: 13px;
      }
    }
  }
  .calendar-default {
    .fc-daygrid-dot-event {
      .fc-event-title {
        text-overflow: ellipsis;
      }
    }
    .fc {
      td[role="presentation"] {
        border: 0;
      }
      .fc-daygrid-day-number,
      .fc-col-header-cell-cushion {
        color: $theme-body-font-color;
      }
      .fc-button {
        .fc-icon {
          height: 23px;
        }
      }
    }
    .fc-h-event {
      padding: 2px;
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
    .fc-toolbar-chunk {
      .fc-button-group {
        ~.fc-today-button {
          margin: 0 8px;
        }
      }
    }
  }
}
.fc-event-dragging {
  padding: 10px;
  background-color: var(--theme-default);
}
.fc {
  .fc-toolbar {
    flex-wrap: wrap;
    gap: 10px;
  }
  .fc-button-primary {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5);
    }
  }
}
// flatpickr
.main-inline-calender {
  &.input-group {
    flex-direction: column;
    .form-control {
      width: 100%;
    }
    .flatpickr-calendar {
      &.inline {
        width: 100%;
      }
    }
    .flatpickr-innerContainer {
      display: block;
    }
    .flatpickr-rContainer {
      display: block;
      .flatpickr-weekdays {
        width: 100%;
      }
      .flatpickr-days {
        width: 100%;
        .dayContainer {
          width: 100%;
          max-width: none;
          min-width: unset;
          .flatpickr-day {
            border-radius: 5px;
            width: 100%;
            max-width: unset;
            &:hover {
              color: var(--body-font-color);
            }
          }
        }
      }
    }
  }
}
.datepicker-inline {
  .datepicker {
    width: 100%;
  }
}
.flatpickr-day {
  &.selected,
  .endRange {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
    &:hover {
      background-color: var(--theme-default);
      border-color: var(--theme-default);
    }
  }
}
.main-flatpickr {
  .form-control {
    &:read-only {
      background-color: unset;
    }
  }
}
.flatpickr-months {
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-current-month {
  font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
}
.flatpickr-calendar {
  &.animate {
    @media (max-width:400px) {
      width: 77%;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  .flatpickr-days {
    width: unset;
    .dayContainer {
      width: unset;
      min-width: unset;
      max-width: unset;
    }
  }
}
.flatpicker-calender {
  .form-control {
    @media (max-width:768px) {
      font-size: 14px;
    }
  }
}
.timepicker-wrapper {
  .row {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/**=====================
   2.13 Datapicker CSS End
==========================**/