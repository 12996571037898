.top-navigation {
    position: fixed;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    line-height: inherit;
    top: 69px;
    background: #f6f8fc;
    /* box-shadow: 0px 9px 24px rgba(92, 97, 242, 0.05); */
    overflow-x: hidden;
    overflow-y: visible;
    border-bottom: 1px solid rgba(106, 113, 133, 0.2);
    z-index: 3;
}

.top-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
}

.top-nav-item svg {
    width: 24px;
    height: 24px;
    stroke: #333;
    transition: stroke 0.3s;
}

.top-nav-item:hover svg {
    stroke: #007bff;
}

@media (min-width: 768px) {
    .top-nav {
        display: none;
    }
}

/* BottomNavigation.css */

.top-navigation {
    display: flex;
    align-items: center;
}

.top-menu-item {
    position: relative; 
    cursor: pointer;
}
.top-menu-item .svg_size svg {
    width: 20px;
    height: 20px;
}
.top-menu-item .text-dark {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.top-menu-subitem {
    position: relative;
}

.top-dropdown-content {
    border: 1px solid var(--theme-default);
    border-radius: 5px;
    position: fixed;
    background-color: white;
    z-index: 1;
    top: 110px;
    max-height: 60%;
    max-width: 80%;
    overflow: auto;
}
.top-navigation
    .top-menu-item:nth-child(3)
    .top-menu-subitem
    .top-dropdown-content {
    right: 0;
    left: auto;
}
.top-navigation
    .top-menu-item:last-child
    .top-menu-subitem
    .top-dropdown-content {
    right: 0;
    left: auto;
}
.top-dropdown-content a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
}
.top-dropdown-content svg{
    margin-right: 10px;
}
.top-menu-subitem .dropdown-toggle-icon{
    width: 12px !important;
    height: 12px !important;
    margin-left: 7px;
}
/* .top-navigation
    .top-menu-item:last-child
    .top-menu-subitem:hover
    .top-dropdown-content {
    display: flex;
} */
.top-dropdown-content a:hover {
    background-color: #f1f1f1;
}
.top-dropdown-content .active a {
    color: var(--theme-default);
}

@media (min-width: 992px) {
    .top-navigation {
        display: none;
    }
}
@media (max-width: 630px) {
    .top-navigation {
        top: 64px;
    }
}
@media (max-width: 420px) {
    .top-navigation {
        top: 58px;
    }
   
}

.top-navigation .active {
    color: var(--theme-default) !important;
}
.bottom-dropdown-content {
    .active {
        color: var(--theme-default);
    }
}
.submenu_secondrow{
    display: none;
}
.fa-chevron-down{
    transform: rotate(0) !important;
    transition: transform 0.3s ease;
    font-size: 11px;
    margin-left: 5px;
}
.uparrow{
    transform: rotate(180deg) !important;
    transition: transform 0.3s ease;
}