.quiz-bg{
    background-image: url('../../images/game-bg1.png');
   background-repeat: no-repeat; 
   background-position: bottom;
   background-size: cover;
}
._1HvZ2MIJpyR9NmMRQbeokO ._2K6khW87cf5FWvjCSSXG9z {
    background: #66108b;
    box-shadow: 0 10px 0 rgba(51, 182, 255, .45);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 40px;
}
.quiz-content{
    .progress-bar{
        background-color: #7e418b;
    }
}
.bg_purple{
    background-color: #7e418b !important;
}
.exam-quiz{
    
}
.quiz-sidebar{
    width: 305px;
@media only screen and (max-width: 592px) {
    width: 100%;
}
}
/* ._1HvZ2MIJpyR9NmMRQbeokO {


    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    margin: 80px auto;
    padding: 40px;
    text-align: center;
    color: #000;
    background: #fff;
    border-radius: 8px;
    max-width: 768px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .15);
    -webkit-animation: _2PMOgfvnQmiLelrHfg3qRO .4s cubic-bezier(0,1.65,.635,.995);
    animation: _2PMOgfvnQmiLelrHfg3qRO .4s cubic-bezier(0,1.65,.635,.995);
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
} */
 
/* .page-body {
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
} */

.quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: #d09bdcb8;
    padding: 50px;
border-radius: 20px;
max-width: 800px;
width: 100%;
}

.question {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.timer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 40px;
}

.timer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 1;
    position: relative; /* Allow positioning of the background layer */
}

.line {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: gray;
}

.line-left {
    left: 50px;
}

.line-right {
    right: 50px;
}

.answers-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for the answers */
    gap: 20px; /* Space between the options */
    width: 100%; /* Full width */
    max-width: 400px; /* Limit the width of the grid */
    margin: 0 auto; /* Center the grid */
}

.option-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-item button {
    width: 100%; /* Full width of the grid item */ 
    font-size: 1.2rem;
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}
.question-container {
    background-color: white; /* White background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding for spacing */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

/* Styles for the total question display */
.total-question {
    background: linear-gradient(90deg, #7e418b, #9e6ab1); /* Gradient background */
    color: white; /* Text color */
    padding: 10px; /* Padding for spacing */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 20px; /* Space below the total question display */
}
.option-item button:hover {
    background-color: #e0e0e0;
}

/* Animation for timer background */
.timer.animate__heartBeat {
    animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.1);
    }
}
._2K6khW87cf5FWvjCSSXG9z {
    background: #66108b;
    box-shadow: 0 10px 0 rgba(51, 182, 255, .45);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 40px;
}
.answebtn{
    background-color: transparent!important;
    border-color: #66108b !important;
}
.answebtnfill{
    background-color: #66108b!important;
    border-color: #66108b !important;
}
.winner-animations {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.winner {
    font-size: 2rem;
    margin: 10px;
} 
/* Techer Screen */
.winnerscreen_bg{
    background-image: url('../../images/teacher-game-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .main_wineerbox{
        height: 85dvh;
        max-width: 90vmin;
        margin: 32px auto 0px;
        display: flex ; 
        justify-content: flex-end;
        align-items: end;
    }
    .first_num{
        position: relative;
        text-align: center;
        color: rgb(51, 51, 51);
        border-radius: 0.375rem 0.375rem 0px 0px;
        display: flex ;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 37px 37px 0px;
        z-index: 2;
        order: 2;
        /* margin-top: -60%; */
        height: calc(100% - 24vh);
    
    }
    .second_num{
        position: relative;
        text-align: center; 
        color: rgb(51, 51, 51);
        border-radius: 0.375rem 0.375rem 0px 0px;
        display: flex;
        /* max-width: 33%;
        flex: 0 1 33%; */
        flex-direction: column;
        justify-content: flex-start; 
        z-index: 1; 
        margin-top: -42%;
        order: 1;
        height:  calc(100% - 35vh);

    }
    .third_num{
        position: relative;
        text-align: center; 
        color: white;
        border-radius: 0.375rem 0.375rem 0px 0px;
        display: flex ;
        /* max-width: 33%;
        flex: 0 1 33%; */
        flex-direction: column;
        -webkit-box-pack: start;
        justify-content: flex-start; 
        z-index: 0; 
        order: 3;
        height: calc(100% - 45vh);
        margin-top: -30%;
    }
}
.winner_box{ 
    width: 380px;
    display: flex ;
    justify-content: center;
    align-items: center;
     
}
.medal {
	animation: rotateY-anim 2s linear infinite;
    height: 12.5vmin !important;
}
.page-wrapper{
    .page-body-wrapper{
/* 
    .winer_Screen{
        height: calc(100vh - 200px) !important;
    } */
}
} 
@keyframes rotateY-anim {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(360deg);
	}
}