/**=====================
    2.5 Bookmark CSS start
==========================**/
.bookmark {
  ul {
    li {
      display: inline-block;

      .search-form {
        position: absolute;
        bottom: 53px;
        z-index: 1;

        .form-control-search {
          top: 65px;
          position: absolute;
          transition: all linear 0.3s;
          right: -20px;
          transform: translateY(-35px) scaleY(0);
          opacity: 0;
          visibility: hidden;

          input {
            width: 240px;
            background-color: $white;
            box-shadow: 0 5px 21px rgba(0, 0, 0, 0.1);
          }

          &.open {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: all linear 0.3s;
            z-index: 100;
          }

          &:after {
            top: 12px;
            left: 20px;
            z-index: 1;
          }

          &:before {
            left: 50px;
            top: 9px;
            z-index: 2;
          }
        }
      }

      a {
        position: relative;
        color: $theme-body-font-color;

        svg {
          vertical-align: middle;
          height: 20px;
          width: 20px;
        }
      }

      +li {
        margin-left: 10px;
      }

      &:last-child {
        padding-right: 0;

        a {
          border-radius: 5px;

          svg {
            color: #ff9f40;
          }
        }
      }
    }
  }
}
.form-check{
  padding-left: 0;
}
.search-options-radio {
display: flex;
gap: 30px;
row-gap: 10px; 
  flex-wrap: wrap;
}
.suggestion_box{
  max-height: 150px;
  overflow: auto;
}
.saved_serch{
  background: var(--theme-default);
  color: white;
  border-radius:5px;
  cursor:pointer; 
  
  &:hover{
    color:white;
  } 
}
.saved_serch h3{
  color: white;

}
.dark-only .saved_serch{
  color: white;
}
.search-options-radio div {
  display: flex;
  gap: 6px;
  justify-content: start;
  align-items: center;

  label {
    white-space: nowrap;
    margin-bottom: 0px;

  }
  input{
    margin-top: 0px;

  }
}

@media (max-width: 768px) {
  .search-options-radio {
    gap:25px;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.offcanvas-bookmark {
  &.page-wrapper.horizontal-wrapper {
    .page-body-wrapper {
      .page-body {
        .bookmark {
          ul {
            li {
              a {
                z-index: 99999;
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
      }
    }
  }

  &:before {
    position: fixed;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 1;
    right: 0;
    left: 0;
  }
}

.form-bookmark {
  .form-color {
    height: 40px;
    width: 8%;
  }
}

body.modal-open {
  .select2-container--open {
    width: unset !important;
    display: block;

    .select2-dropdown {
      z-index: 1076;
    }
  }
}

/**=====================
    2.5 Bookmark CSS Ends
==========================**/