.faq-top { 
    color: white;
  }
  
  .faq-top h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .faq-top p {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }
  
  .faq-top .form-control {
    font-size: 1.2rem;
  }
  
  .dark-accordion .accordion-header {
    background-color: #343a40;
    color: white;
    font-weight: 600;
    padding: 15px; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .dark-accordion .accordion-header button{
    color: white;
    justify-content: space-between;
  }
  .dark-accordion .accordion-header:hover {
    background-color: #495057;
  }
  
  .dark-accordion .accordion-body {
    background-color: #f8f9fa;
    color: #333; 
    border-radius: 5px;
    padding: 20px;
  }
  
  .accordion-header .float-end {
    transition: transform 0.3s ease;
  }
  
  .accordion-header:hover .float-end {
    transform: rotate(180deg);
  }
  
  .faq-section {
    margin-top: 50px;
  }
  
  button {
    font-size: 1.1rem;
    border-radius: 50px;
  }
  