.bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
}

.bottom-nav-item svg {
    width: 24px;
    height: 24px;
    stroke: #333;
    transition: stroke 0.3s;
}

.bottom-nav-item:hover svg {
    stroke: #007bff;
}

@media (min-width: 768px) {
    .bottom-nav {
        display: none;
    }
}

/* BottomNavigation.css */

.bottom-navigation {
    display: flex;
    align-items: center;
}

.bottom-menu-item {
    position: relative;
    cursor: pointer;
}
.bottom-menu-item .svg_size svg {
    width: 20px;
    height: 20px;
}
.bottom-navigation .active a {
    color: var(--theme-default) !important;
}
.bottom-dropdown-content {
    .active {
        color: var(--theme-default);
    }
}
.bottom-menu-item .text-dark {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}
.bottom-menu-subitem {
    position: relative;
}

.bottom-dropdown-content {
    border: 1px solid var(--theme-default);
    border-radius: 5px;
    /* display: none; */
    position: absolute; 
    background-color: white;
    z-index: 1;
    width: max-content;
    bottom: 40px;
}
.bottom-dropdown-content svg{
    margin-right: 10px;
}
 
.bottom-menu-subitem .dropdown-toggle-icon{
    width: 12px !important;
    height: 12px !important;
    margin-left: 7px;
}
.bottom-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.bottom-dropdown-content a:hover {
    background-color: #f1f1f1;
}
@media (min-width: 992px) {
    .bottom-navigation {
        display: none;
    }
}
.text-primary{
    color: #ff6600 !important;
}
.iconactive span svg {
    color: var(--theme-default) !important;
}